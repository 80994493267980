export default [
  {
    title: '产品概述',
    path: '/overview',
    children: [
      {
        title: '产品定义',
        path: '/overview/definition',
      },
      {
        title: '产品优势',
        path: '/overview/advantage',
      },
      {
        title: '应用场景',
        path: '/overview/scene',
      },
      {
        title: '产品性能',
        path: '/overview/performance',
      },
      {
        title: '产品特性',
        path: '/overview/characteristic',
      },
      {
        title: '基本概念',
        path: '/overview/concept',
      },
      {
        title: '产品架构',
        path: '/overview/framework',
      },
    ],
  },
  {
    title: '产品计费',
    path: '/charging',
    children: [],
  },
  {
    title: '快速上手',
    path: '/quickly',
    children: [
      {
        title: '数据概览',
        path: '/quickly/overview',
      },
      {
        title: '存储桶管理',
        path: '/quickly/bucket',
      },
      {
        title: '对象管理',
        path: '/quickly/object',
      },
      {
        title: '文件夹功能',
        path: '/quickly/folder',
      },
    ],
  },
  {
    title: '常用工具',
    path: '/s3tools',
    children: [
      {
        title: 'S3browser',
        path: '/s3tools/browser',
      },
      {
        title: 'S3cmd',
        path: '/s3tools/cmd',
      },
      {
        title: 'S3xplorer',
        path: '/s3tools/xplorer',
      },
      {
        title: 'S3manager',
        path: '/s3tools/manager',
      },
    ],
  },
  {
    title: 'API',
    path: '/api',
    children: [
      {
        title: '概览',
        path: '/api/overview',
      },
      {
        title: '公共 HTTP 头定义',
        path: '/api/publicHttpHeader',
      },
      {
        title: '关于 Service 操作',
        path: '/api/service',
      },
      {
        title: '关于 Bucket 操作',
        path: '/api/bucket',
      },
      {
        title: '关于 Object 操作',
        path: '/api/object',
      },
    ],
  },
  {
    title: 'SDK',
    path: '/sdk',
    children: [
      {
        title: 'Python',
        path: '/sdk/python',
      },
      {
        title: 'Node.js',
        path: '/sdk/nodejs',
      },
      {
        title: 'Go',
        path: '/sdk/golang',
      },
      {
        title: 'PHP',
        path: '/sdk/php',
      },
      {
        title: 'JAVA',
        path: '/sdk/java',
      },
      {
        title: '.NET',
        path: '/sdk/NET',
      },
    ],
  },
  {
    title: '相关协议',
    path: '/agreement',
    children: [
      {
        title: '服务条款',
        path: '/agreement/clause',
      },
      {
        title: '服务等级协议',
        path: '/agreement/level',
      },
    ],
  },
  {
    title: '常见问题',
    path: '/qa',
  },
];
