import React from 'react';
import { Link } from 'gatsby';
import * as styles from './index.module.less';
import LIST from './menuList';

interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export default function DocMenu({ className = '', style }: IProps) {
  return (
    <div className={`${styles.menu} ${className}`} style={style}>
      {LIST.map((item) => {
        if (!item.children?.length) {
          return (
            <dl className={styles.item} key={item.path}>
              <dt>
                <Link className={styles.title} to={`/doc${item.path}`}>
                  {item.title}
                </Link>
              </dt>
            </dl>
          );
        }
        return (
          <dl key={item.path} className={styles.item}>
            <dt>
              <a className={styles.title}>{item.title}</a>
            </dt>
            <dd>
              {item.children?.map((doc) => {
                return (
                  <Link className={styles.subTitle} to={`/doc${doc.path}`} key={doc.path}>
                    {doc.title}
                  </Link>
                );
              })}
            </dd>
          </dl>
        );
      })}
    </div>
  );
}
