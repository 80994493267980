import React, { useState } from 'react';
import { HeadProps, PageProps } from 'gatsby';
import { UnorderedListOutlined } from '@ant-design/icons';
import * as styles from './index.module.less';
import DocMenu from '../../components/DocMenu';
import './markdown.css';
import 'prismjs/themes/prism-solarizedlight.css';
import DocSearch from '../../components/DocSearch';
import SEO from '../../components/SEO';
import { cls, toConsole } from '../../utils';

interface PageContext {
  frontmatter: {
    title: string;
    slug: string;
  };
  id: string;
  html: string;
}

export default function PageTemplate(props: PageProps<any, PageContext>) {
  const { pageContext } = props;
  const [menuVisible, setMenuVisible] = useState(false);

  const { html, frontmatter } = pageContext;

  return (
    <div className={cls(styles.docContainer, menuVisible && styles.menuShow)}>
      <div className={styles.menu}>
        <div className={styles.menuTitle}>土星云产品文档</div>
        <DocMenu />
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.docHeader}>
          <div className={styles.left}>
            <UnorderedListOutlined
              className={styles.menuOperate}
              onClick={() => {
                setMenuVisible((state) => !state);
              }}></UnorderedListOutlined>
            <DocSearch className={styles.search} />
          </div>
          <div>
            <a
              onClick={() => {
                toConsole();
              }}>
              控制台
            </a>
          </div>
        </div>
        <div className={styles.markdownContainer}>
          <h1>{frontmatter.title}</h1>
          <div className="markdown-body" dangerouslySetInnerHTML={{ __html: html }}></div>
        </div>
      </div>
    </div>
  );
}

export function Head(props: HeadProps<object, { frontmatter: { title: string } }>) {
  return <SEO title={`文档-${props.pageContext.frontmatter.title}`} />;
}
